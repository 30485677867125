.material-input {
  width: 100%;
  line-height: 1.1876em;
  letter-spacing: 0.00938em;
  box-sizing: border-box;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  cursor: text;
  color: rgba(0, 0, 0, 0.87);
}

.material-input.material-input--focused .material-input__label {
  -webkit-transform-origin: top left;
          transform-origin: top left;
  -webkit-transform: translate(14px, -6px) scale(0.75);
          transform: translate(14px, -6px) scale(0.75);
}

.material-input.material-input--focused .material-input__form-control:hover .material-input__bg {
  border-color: #A0A9C5;
}

@media (hover: none) {
  .material-input.material-input--focused .material-input__form-control:hover .material-input__bg {
    border-color: rgba(0, 0, 0, 0.23);
  }
}

.material-input.material-input--focused .material-input__bg {
  border-color: rgba(0, 0, 0, 0.23);
}

.material-input.material-input--focused .material-input__placeholder {
  max-width: 1000px;
  -webkit-transition: max-width 100ms cubic-bezier(0, 0, 0.2, 1) 50ms;
  transition: max-width 100ms cubic-bezier(0, 0, 0.2, 1) 50ms;
}

.material-input:hover .material-input__bg {
  border-color: #A0A9C5;
}

.material-input .material-input__form-control-wrapper {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  position: relative;
  width: 100%;
}

.material-input .material-input__label {
  color: rgba(0, 0, 0, 0.54);
  padding: 0;
  line-height: 1;
  letter-spacing: 0.00938em;
  display: block;
  -webkit-transform-origin: top left;
          transform-origin: top left;
  top: 0;
  left: 0;
  position: absolute;
  -webkit-transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms, -webkit-transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms, -webkit-transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms, transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms, transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms, -webkit-transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  z-index: 1;
  -webkit-transform: translate(14px, 20px) scale(1);
          transform: translate(14px, 20px) scale(1);
  pointer-events: none;
}

.material-input .material-input__form-control_password {
  position: relative;
}

.material-input .material-input__form-control_password-control {
  position: absolute;
  top: 15px;
  right: 15px;
  display: inline-block;
  width: 22px;
  height: 22px;
  color: #A0A9C5;
  cursor: pointer;
}

.material-input .material-input__form-control {
  width: 100%;
  color: rgba(0, 0, 0, 0.87);
  cursor: text;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  position: relative;
  box-sizing: border-box;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  line-height: 1.1876em;
  letter-spacing: 0.00938em;
  border-radius: 4px;
}

.material-input .material-input__input {
  border-radius: 5px;
  border: 1px solid #E9EDFB;
  color: #1D1D1F;
  font-family: Raleway;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  width: 100%;
  height: 46px;
  margin: 0;
  display: block;
  min-width: 0;
  background: none;
  box-sizing: content-box;
  letter-spacing: inherit;
  -webkit-animation-duration: 10ms;
          animation-duration: 10ms;
  padding: 0 11px;
}

.material-input .material-input__input:focus {
  outline: 0;
}

.material-input .material-input__input:invalid {
  box-shadow: none;
}

.material-input .material-input__bg {
  top: -5px;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0;
  padding: 0 8px;
  overflow: hidden;
  position: absolute;
  border-color: #C2C2C2;
  border-style: solid;
  border-width: 1px;
  border-radius: inherit;
  pointer-events: none;
}

.material-input .material-input--errored {
  border-color: red;
}

.material-input .material-input__error {
  font-size: 14px;
  color: red;
}

.material-input .material-input__placeholder {
  width: auto;
  height: 11px;
  display: block;
  max-width: 0.01px;
  -webkit-transition: max-width 50ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  transition: max-width 50ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  visibility: hidden;
}

.material-input .material-input__placeholder span {
  display: inline-block;
  padding-left: 5px;
  padding-right: 5px;
}

.social-auth {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  gap: 30px;
}

.social-auth .social-auth__buttons {
  width: 30px;
}

.social-auth .social-auth__title {
  padding-bottom: 10px;
  font-size: 15px;
  line-height: 18px;
  color: #000000;
}

.auth-page {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin: 40px 0;
  width: 100%;
}

.auth-page .auth-page__inner {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.auth-page .auth-page__inner .auth-page__sidebar {
  background-image: url("~@img/auth/sidebar-background.webp");
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
}

.auth-page .auth-page__inner .auth-page__sidebar .sidebar__inner {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 40px;
}

.auth-page .auth-page__inner .auth-page__sidebar .sidebar__inner_full-height {
  height: 100%;
}

.auth-page .auth-page__inner .auth-page__sidebar .sidebar__welcome {
  color: #A0A9C5;
  font-family: Raleway;
  font-size: 40px;
  font-style: normal;
  font-weight: 800;
  letter-spacing: 0.15px;
}

.auth-page .auth-page__inner .auth-page__sidebar .sidebar__list {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 10px;
  width: 100%;
  margin-top: 100px;
}

.auth-page .auth-page__inner .auth-page__sidebar .sidebar__list .list__item {
  padding: 21px 0 21px 40px;
  cursor: pointer;
}

.auth-page .auth-page__inner .auth-page__sidebar .sidebar__list .active {
  background-color: #FFF;
}

.auth-page .auth-page__inner .auth-page__sidebar .sidebar__welcome_bottom {
  margin-top: auto;
}

.auth-page .auth-page__inner .auth-page__sidebar .sidebar__text {
  color: #1D1D1F;
  font-family: Raleway;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.15px;
}

.auth-page .auth-page__inner .auth-page__form {
  border-radius: 5px;
  border: 2px solid #E9EDFB;
  background: #FFF;
}

.auth-page .auth-page__inner .auth-page__form .auth-page__form-inner {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.auth-page .auth-page__inner .auth-page__form .auth-form__integrated-section {
  display: none;
}

.auth-page .auth-page__inner .auth-page__form .auth-form__inner {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 20px;
}

.auth-page .auth-page__inner .auth-page__form .auth-form__back-to-role {
  display: none;
}

.auth-page .auth-page__inner .auth-page__form .auth-form__inner_margin-top {
  margin-top: 20px;
}

.auth-page .auth-page__inner .auth-page__form .auth-form__title {
  color: #1D1D1F;
  font-family: Raleway;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  text-align: center;
  margin-bottom: 14px;
}

.auth-page .auth-page__inner .auth-page__form .auth-form__row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 10px;
}

.auth-page .auth-page__inner .auth-page__form .auth-form__row_right-leveling {
  padding-bottom: 0;
  margin-left: auto;
}

.auth-page .auth-page__inner .auth-page__form .auth-form__btn {
  width: 100%;
  display: block;
  padding: 15px;
  margin-top: 10px;
  text-decoration: none;
  cursor: pointer;
  font-family: Raleway;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border-radius: 5px;
  text-align: center;
}

.auth-page .auth-page__inner .auth-page__form .auth-form__btn-filled {
  background: #E9EDFB;
  color: #000000;
}

.auth-page .auth-page__inner .auth-page__form .auth-form__btn-filled:hover {
  background: #A0A9C5;
}

.auth-page .auth-page__inner .auth-page__form .auth-form__btn-link {
  padding: 0;
  color: #A0A9C5;
  font-family: Raleway;
  font-style: normal;
  font-weight: 500;
}

.auth-page .auth-page__inner .auth-page__form .auth-form__btn-link_normal {
  font-size: 16px;
  line-height: 18.78px;
}

.auth-page .auth-page__inner .auth-page__form .auth-form__btn-link_text-underline {
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
}

.auth-page .auth-page__inner .auth-page__form .auth-form__btn-link_small {
  font-size: 14px;
  line-height: 16px;
  text-decoration: none;
}

@media only screen and (max-width: 386px) {
  .auth-page .auth-page__inner .auth-page__form .auth-form__btn-link_left-margin {
    margin-left: 0;
  }
}

@media only screen and (min-width: 387px) {
  .auth-page .auth-page__inner .auth-page__form .auth-form__btn-link_left-margin {
    margin-left: 10px;
  }
}

@media only screen and (min-width: 397px) {
  .auth-page .auth-page__inner .auth-page__form .auth-form__btn-link_left-margin {
    margin-left: 20px;
  }
}

.auth-page .auth-page__inner .auth-page__form .auth-form__btn-link_top-margin {
  margin-top: 10px;
}

.auth-page .auth-page__inner .auth-page__form .auth-form__or-text {
  color: #A0A9C5;
  font-family: Raleway;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 15px;
}

.auth-page .auth-page__inner .auth-page__form .auth-form__or-text:before, .auth-page .auth-page__inner .auth-page__form .auth-form__or-text:after {
  content: '';
  display: inline-block;
  height: 1px;
  background: #A0A9C5;
  width: -webkit-calc(50% - 20.6px);
  width: calc(50% - 20.6px);
  margin: 0 auto 4px;
}

.auth-page .auth-page__inner .auth-page__form .auth-form__text {
  color: #000;
  font-family: Raleway;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.15px;
  text-align: center;
}

.auth-page .auth-page__inner .auth-page__form .auth-form__text_normal {
  font-size: 18px;
}

.auth-page .auth-page__inner .auth-page__form .auth-form__text_small {
  font-size: 16px;
}

@media only screen and (min-width: 397px) {
  .auth-page .auth-page__inner .auth-page__form .auth-form__text_left {
    text-align: left;
  }
}

@media only screen and (min-width: 320px) {
  .auth-page .auth-page__inner {
    width: 100%;
  }
  .auth-page .auth-page__inner .auth-page__sidebar {
    display: none;
  }
  .auth-page .auth-page__inner .auth-page__form {
    width: 100%;
  }
  .auth-page .auth-page__inner .auth-page__form .auth-page__form-inner {
    padding: 65px 15px 163px 15px;
  }
  .auth-page .auth-page__inner .auth-page__form .auth-form__title {
    font-size: 27px;
  }
}

@media only screen and (min-width: 550px) {
  .auth-page .auth-page__inner .auth-page__form .auth-page__form-inner {
    padding: 65px 35px 163px 35px;
  }
  .auth-page .auth-page__inner .auth-page__form .auth-form__title {
    font-size: 40px;
  }
}

@media only screen and (min-width: 768px) {
  .auth-page .auth-page__inner {
    width: 600px;
  }
  .auth-page .auth-page__inner .auth-page__form .auth-page__form-inner {
    padding: 65px 65px 163px 65px;
  }
}

@media only screen and (min-width: 992px) {
  .auth-page .auth-page__inner {
    width: 800px;
  }
  .auth-page .auth-page__inner .auth-page__sidebar {
    width: -webkit-calc(4 * 100% / 11);
    width: calc(4 * 100% / 11);
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
  .auth-page .auth-page__inner .auth-page__sidebar .sidebar__inner {
    padding: 40px 20px;
  }
  .auth-page .auth-page__inner .auth-page__form {
    width: -webkit-calc(7 * 100% / 11);
    width: calc(7 * 100% / 11);
  }
  .auth-page .auth-page__inner .auth-page__form_with-sidebar {
    border-radius: 0 5px 5px 0;
    border-left: none;
  }
}

@media only screen and (min-width: 1200px) {
  .auth-page .auth-page__inner {
    width: 1100px;
  }
  .auth-page .auth-page__inner .auth-page__sidebar .sidebar__inner {
    padding: 40px;
  }
  .auth-page .auth-page__inner .auth-page__form .auth-page__form-inner {
    padding: 65px 134px 163px 134px;
  }
}

@media only screen and (min-width: 1310px) {
  .auth-page .auth-page__inner .auth-page__form .auth-page__form-inner {
    padding: 65px 162px 163px 162px;
  }
}
